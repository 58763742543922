<template>
  <div class="text-center">
    <ion-icon class="icon-error-round" name="alert"></ion-icon>

    <h2>Page Not Found</h2>
    <p>The page you are looking for seems like it might not exist, or your session has expired.</p>
    <p><router-link to="/">Back to home page</router-link></p>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { alert } from "ionicons/icons";

addIcons({
  "ios-alert": alert.ios,
  "md-alert": alert.md
});

export default {
  name: "Success"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon-error-round {
  margin-top: 80px;
  font-size: 100px;
  color: #b40000;
}
</style>
